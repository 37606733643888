var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"mt-4"},[(_vm.getCompetitionGroups.length == 0)?_c('div',[_c('b-alert',{attrs:{"show":"","variant":"warning"}},[_vm._v("Kayıt bulunamadı")])],1):_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.getCompetitionGroups,"striped":"","responsive":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(id){return [_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v(" "+_vm._s(id.index + 1)+" ")])]}},{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ width: field.key === 'name' ? '80%' : '10%' })})})}},{key:"cell(name)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.competition.name)+" ")]}},(_vm.isFederation())?{key:"cell(tools)",fn:function(row){return [_c('b-button-group',[_c('b-button',{attrs:{"size":"sm","to":{
            name: 'GroupDetail',
            params: {
              id: row.item.id,
              competitionId: row.item.competitionId,
            },
          }}},[_c('b-icon-search')],1),_c('b-button',{attrs:{"size":"sm","to":{
            name: 'EditGroup',
            params: {
              id: row.item.id,
              competitionId: row.item.competitionId,
            },
          }}},[_c('b-icon-pencil')],1)],1)]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }