<template>
  <b-container class="mt-4">
    <div v-if="getCompetitionGroups.length == 0">
      <b-alert show variant="warning">Kayıt bulunamadı</b-alert>
    </div>
    <b-table
      :fields="fields"
      :items="getCompetitionGroups"
      striped
      responsive
      v-else
    >
      <template #cell(id)="id">
        <b-badge variant="secondary">
          {{ id.index + 1 }}
        </b-badge>
      </template>
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'name' ? '80%' : '10%' }"
        />
      </template>
      <template #cell(name)="row">
        {{ row.item.competition.name }}
      </template>
      <template #cell(tools)="row" v-if="isFederation()">
        <b-button-group>
          <b-button
            size="sm"
            :to="{
              name: 'GroupDetail',
              params: {
                id: row.item.id,
                competitionId: row.item.competitionId,
              },
            }"
          >
            <b-icon-search></b-icon-search>
          </b-button>
          <b-button
            size="sm"
            :to="{
              name: 'EditGroup',
              params: {
                id: row.item.id,
                competitionId: row.item.competitionId,
              },
            }"
          >
            <b-icon-pencil></b-icon-pencil>
          </b-button>
        </b-button-group>
      </template>
    </b-table>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CompetitionDisplay",
  props: ["form"],
  computed: {
    ...mapGetters(["getCompetitionGroups"]),
  },
  data() {
    return {
      fields: [
        { label: "Sıra", key: "id" },
        { label: "Yarışmanın Adı", key: "name" },
        { label: "İşlemler", key: "tools" },
      ],
    };
  },
  created() {
    this.$store.dispatch("initPersonelCompetitionGroups", this.form.id);
  },
  methods: {
    isFederation() {
      return this.$store.getters.checkToken.isFederation;
    },
  },
};
</script>

<style scoped>
</style>