<template>
  <b-container class="mt-4">
    <b-row>
      <b-col>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold"> Kulüp : </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ getClubName(form.branch.clubs) }}
          </b-col>
        </b-row>
        <b-row v-if="form.branch.secondClubs">
          <b-col class="mt-4 text-danger font-weight-bold">
            İkinci Kulüp :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ getClubName(form.branch.secondClubs) }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold"> Boy : </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.size }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold"> Kilo : </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.weight }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold"> Beden : </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.bodySize }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Ayakkabı No :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.shoeSize }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Spor Ayakkabı No :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.sportsShoeSize }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold"> Branş : </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.branch }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold"> Kategori : </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.category }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Tekne Sınıfı :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.boatClass }}
          </b-col>
        </b-row>
        <h4><b-badge class="mt-4"> SPORCU BİLGİLERİ </b-badge></h4>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold"> Sporcu : </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.sportState }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">Ferdi Sporcu : </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.individual }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Sporcu Lisans No :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.sportLicenceNumber }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Sporcu Son Vize Yıl :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{
              form.branch.sportLastLicenseDate
                ? form.branch.sportLastLicenseDate
                : "Yok"
            }}
          </b-col>
        </b-row>
        <h4><b-badge class="mt-4"> HAKEM BİLGİLERİ </b-badge></h4>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold"> Hakem : </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.sportRefereeState }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Hakem Son Vize Yıl :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{
              form.branch.sportRefereeLastLicenseDate
                ? form.branch.sportRefereeLastLicenseDate
                : "Yok"
            }}
          </b-col>
        </b-row>
        <h4><b-badge class="mt-4"> ANTRENÖR BİLGİLERİ </b-badge></h4>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold"> Antrenör : </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.trainerState }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Antrenör Son Vize Yıl :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{
              form.branch.trainerLastLicenseDate
                ? form.branch.trainerLastLicenseDate
                : "Yok"
            }}
          </b-col>
        </b-row>
        <h4><b-badge class="mt-4"> DURGUNSU HAKEM BİLGİLERİ </b-badge></h4>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Aday Hakem Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.stillWaterCandidateRefereePromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Bölge Hakem Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.stillWaterDistrictRefereePromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Milli Hakem Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.stillWaterNationalRefereePromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Uluslararası Hakem Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.stillWaterInternationalRefereePromotionDate }}
          </b-col>
        </b-row>

        <h4><b-badge class="mt-4">DENİZ KANOSU HAKEM BİLGİLERİ</b-badge></h4>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Aday Hakem Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.seaKayakingCandidateRefereePromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Bölge Hakem Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.seaKayakingDistrictRefereePromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Milli Hakem Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.seaKayakingNationalRefereePromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Uluslararası Hakem Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.seaKayakingInternationalRefereePromotionDate }}
          </b-col>
        </b-row>

        <h4><b-badge class="mt-4">RAFTİNG HAKEM BİLGİLERİ</b-badge></h4>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Aday Hakem Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.raftingCandidateRefereePromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Bölge Hakem Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.raftingDistrictRefereePromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Milli Hakem Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.raftingNationalRefereePromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Uluslararası Hakem Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.raftingInternationalRefereePromotionDate }}
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <h4><b-badge class="mt-4"> AKARSU HAKEM BİLGİLERİ </b-badge></h4>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Aday Hakem Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.runningWaterCandidateRefereePromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Bölge Hakem Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.runningWaterDistrictRefereePromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Milli Hakem Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.runningWaterNationalRefereePromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Uluslararası Hakem Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.runningWaterInternationalRefereePromotionDate }}
          </b-col>
        </b-row>

        <h4><b-badge class="mt-4"> DURGUNSU ANTRENÖR BİLGİLERİ </b-badge></h4>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Yardımcı Antrenör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.stillWaterAssistantTrainerPromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Antrenör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.stillWaterTrainerPromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Kıdemli Antrenör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.stillWaterSeniorTrainerPromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Baş Antrenör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.stillWaterHeadTrainerPromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Teknik Direktör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.stillWaterCoachPromotionDate }}
          </b-col>
        </b-row>
        <h4><b-badge class="mt-4"> AKARSU ANTRENÖR BİLGİLERİ </b-badge></h4>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Yardımcı Antrenör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.runningWaterAssistantTrainerPromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Antrenör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.runningWaterTrainerPromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Kıdemli Antrenör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.runningWaterSeniorTrainerPromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Baş Antrenör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.runningWaterHeadTrainerPromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Teknik Direktör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.runningWaterCoachPromotionDate }}
          </b-col>
        </b-row>
        <h4>
          <b-badge class="mt-4"> DENİZ KANOSU ANTRENÖR BİLGİLERİ </b-badge>
        </h4>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Yardımcı Antrenör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.seaKayakingAssistantTrainerPromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Antrenör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.seaKayakingTrainerPromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Kıdemli Antrenör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.seaKayakingSeniorTrainerPromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Baş Antrenör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.seaKayakingHeadTrainerPromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Teknik Direktör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.seaKayakingCoachPromotionDate }}
          </b-col>
        </b-row>
        <h4><b-badge class="mt-4"> RAFTİNG ANTRENÖR BİLGİLERİ </b-badge></h4>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Yardımcı Antrenör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.raftingAssistantTrainerPromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Antrenör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.raftingTrainerPromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Kıdemli Antrenör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.raftingSeniorTrainerPromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Baş Antrenör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.raftingHeadTrainerPromotionDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Teknik Direktör Terfi Tarih :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.raftingCoachPromotionDate }}
          </b-col>
        </b-row>
        <h4><b-badge class="mt-4"> DİĞER BİLGİLERİ </b-badge></h4>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            İl Temsilcisi :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.provincialRepresentative }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Kurul Üyesi :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.assemblyMember }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold"> Kurul : </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.assembly }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold"> Personel : </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.personel }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Personel Görev :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.personelDuty }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold"> Yönetici : </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.manager }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold">
            Yönetici Görev :
          </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.managerDuty }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4 text-danger font-weight-bold"> Açıklama : </b-col>
          <b-col class="mt-4 font-weight-bold">
            {{ form.branch.description }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "BranchDisplay",
  props: ["form"],
  methods: {
    getClubName(club) {
      return club ? club.name : "Yok";
    },
  },
};
</script>

<style scoped>
</style>