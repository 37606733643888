<template>
  <b-container class="mt-4">
    <b-modal id="report-personel" title="Döküm Al" @ok="reportUser">
      <b-col class="text-center"
        ><h5>Dahil edilecek kısımları seçiniz</h5></b-col
      >
      <hr />
      <b-row>
        <b-col class="text-center mb-4">Nüfus Bilgileri</b-col>
        <b-col>
          <b-checkbox v-model="reportForm.includeDetails"></b-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center mb-4">Branş Bilgileri</b-col>
        <b-col>
          <b-checkbox v-model="reportForm.includeBranch"></b-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center mb-4">Yarışma Bilgileri</b-col>
        <b-col>
          <b-checkbox
            v-model="reportForm.includeCompetitionGroups"
          ></b-checkbox>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="visa-modal" title="Vize İşlemleri" ok-only>
      <b-row class="text-center mb-4">
        <b-col><b>Sporcu</b></b-col>
      </b-row>
      <b-row class="text-center mb-2">
        <b-col>
          Akarsu Son vize tarihi:
          {{
            form.branch.streamWaterSportLastLicenseDate &&
            form.branch.streamWaterSportLastLicenseDate != "0"
              ? form.branch.streamWaterSportLastLicenseDate
              : "Yok"
          }}
        </b-col>
        <b-col>
          <b-button
            @click="visaPersonel('streamWaterSportLastLicenseDate')"
            variant="primary"
          >
            Ekle
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-center mb-2">
        <b-col>
          Durgunsu Son vize tarihi:
          {{
            form.branch.stillWaterSportLastLicenseDate &&
            form.branch.stillWaterSportLastLicenseDate != "0"
              ? form.branch.stillWaterSportLastLicenseDate
              : "Yok"
          }}
        </b-col>
        <b-col>
          <b-button
            @click="visaPersonel('stillWaterSportLastLicenseDate')"
            variant="primary"
          >
            Ekle
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-center mb-2">
        <b-col>
          Deniz Kanosu Son vize tarihi:
          {{
            form.branch.seaKayakingSportLastLicenseDate &&
            form.branch.seaKayakingSportLastLicenseDate != "0"
              ? form.branch.seaKayakingSportLastLicenseDate
              : "Yok"
          }}
        </b-col>
        <b-col>
          <b-button
            @click="visaPersonel('seaKayakingSportLastLicenseDate')"
            variant="primary"
          >
            Ekle
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-center mb-2">
        <b-col>
          DragonBoat Son vize tarihi:
          {{
            form.branch.dragonBoatSportLastLicenseDate &&
            form.branch.dragonBoatSportLastLicenseDate != "0"
              ? form.branch.dragonBoatSportLastLicenseDate
              : "Yok"
          }}
        </b-col>
        <b-col>
          <b-button
            @click="visaPersonel('dragonBoatSportLastLicenseDate')"
            variant="primary"
          >
            Ekle
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-center mb-2">
        <b-col>
          Rafting Son vize tarihi:
          {{
            form.branch.raftingSportLastLicenseDate &&
            form.branch.raftingSportLastLicenseDate != "0"
              ? form.branch.raftingSportLastLicenseDate
              : "Yok"
          }}
        </b-col>
        <b-col>
          <b-button
            @click="visaPersonel('raftingSportLastLicenseDate')"
            variant="primary"
          >
            Ekle
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-center mb-4">
        <b-col><b>Antrenör</b></b-col>
      </b-row>
      <b-row class="text-center mb-2">
        <b-col>
          Akarsu Son vize tarihi:
          {{
            form.branch.streamWaterTrainerLastLicenseDate &&
            form.branch.streamWaterTrainerLastLicenseDate != "0"
              ? form.branch.streamWaterTrainerLastLicenseDate
              : "Yok"
          }}
        </b-col>
        <b-col>
          <b-button
            @click="visaPersonel('streamWaterTrainerLastLicenseDate')"
            variant="primary"
          >
            Ekle
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-center mb-2">
        <b-col>
          Durgunsu Son vize tarihi:
          {{
            form.branch.stillWaterTrainerLastLicenseDate &&
            form.branch.stillWaterTrainerLastLicenseDate != "0"
              ? form.branch.stillWaterTrainerLastLicenseDate
              : "Yok"
          }}
        </b-col>
        <b-col>
          <b-button
            @click="visaPersonel('stillWaterTrainerLastLicenseDate')"
            variant="primary"
          >
            Ekle
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-center mb-2">
        <b-col>
          Deniz Kanosu Son vize tarihi:
          {{
            form.branch.seaKayakingTrainerLastLicenseDate &&
            form.branch.seaKayakingTrainerLastLicenseDate != "0"
              ? form.branch.seaKayakingTrainerLastLicenseDate
              : "Yok"
          }}
        </b-col>
        <b-col>
          <b-button
            @click="visaPersonel('seaKayakingTrainerLastLicenseDate')"
            variant="primary"
          >
            Ekle
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-center mb-2">
        <b-col>
          Dragonboat Son vize tarihi:
          {{
            form.branch.dragonBoatTrainerLastLicenseDate &&
            form.branch.dragonBoatTrainerLastLicenseDate != "0"
              ? form.branch.dragonBoatTrainerLastLicenseDate
              : "Yok"
          }}
        </b-col>
        <b-col>
          <b-button
            @click="visaPersonel('dragonBoatTrainerLastLicenseDate')"
            variant="primary"
          >
            Ekle
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-center mb-2">
        <b-col>
          Rafting Son vize tarihi:
          {{
            form.branch.raftingTrainerLastLicenseDate &&
            form.branch.raftingTrainerLastLicenseDate != "0"
              ? form.branch.raftingTrainerLastLicenseDate
              : "Yok"
          }}
        </b-col>
        <b-col>
          <b-button
            @click="visaPersonel('raftingTrainerLastLicenseDate')"
            variant="primary"
          >
            Ekle
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-center mb-4">
        <b-col><b>Hakem</b></b-col>
      </b-row>
      <b-row class="text-center mb-2">
        <b-col>
          Akarsu Son vize tarihi:
          {{
            form.branch.streamWaterSportRefereeLastLicenseDate &&
            form.branch.streamWaterSportRefereeLastLicenseDate != "0"
              ? form.branch.streamWaterSportRefereeLastLicenseDate
              : "Yok"
          }}
        </b-col>
        <b-col>
          <b-button
            @click="visaPersonel('streamWaterSportRefereeLastLicenseDate')"
            variant="primary"
          >
            Ekle
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-center mb-2">
        <b-col>
          Durgunsu Son vize tarihi:
          {{
            form.branch.stillWaterSportRefereeLastLicenseDate &&
            form.branch.stillWaterSportRefereeLastLicenseDate != "0"
              ? form.branch.stillWaterSportRefereeLastLicenseDate
              : "Yok"
          }}
        </b-col>
        <b-col>
          <b-button
            @click="visaPersonel('stillWaterSportRefereeLastLicenseDate')"
            variant="primary"
          >
            Ekle
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-center mb-2">
        <b-col>
          Deniz Kanosu Son vize tarihi:
          {{
            form.branch.seaKayakingSportRefereeLastLicenseDate &&
            form.branch.seaKayakingSportRefereeLastLicenseDate != "0"
              ? form.branch.seaKayakingSportRefereeLastLicenseDate
              : "Yok"
          }}
        </b-col>
        <b-col>
          <b-button
            @click="visaPersonel('seaKayakingSportRefereeLastLicenseDate')"
            variant="primary"
          >
            Ekle
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-center mb-2">
        <b-col>
          Dragonboat Son vize tarihi:
          {{
            form.branch.dragonBoatSportRefereeLastLicenseDate &&
            form.branch.dragonBoatSportRefereeLastLicenseDate != "0"
              ? form.branch.dragonBoatSportRefereeLastLicenseDate
              : "Yok"
          }}
        </b-col>
        <b-col>
          <b-button
            @click="visaPersonel('dragonBoatSportRefereeLastLicenseDate')"
            variant="primary"
          >
            Ekle
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-center mb-2">
        <b-col>
          Rafting Son vize tarihi:
          {{
            form.branch.raftingSportRefereeLastLicenseDate &&
            form.branch.raftingSportRefereeLastLicenseDate != "0"
              ? form.branch.raftingSportRefereeLastLicenseDate
              : "Yok"
          }}
        </b-col>
        <b-col>
          <b-button
            @click="visaPersonel('raftingSportRefereeLastLicenseDate')"
            variant="primary"
          >
            Ekle
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-center"> </b-row>
    </b-modal>
    <b-modal id="warning-modal" title="Vize İşlemleri" centered @ok="handleOk">
      Bu kişi 3 yıldan beri vize yaptırmamış, yine de devam etmek istiyor
      musunuz?
    </b-modal>
    <b-card header-class="header">
      <template #header>
        <label v-text="'Personel Bilgileri'" class="mr-2 text-white"></label>
      </template>
      <div class="buttonBar" v-if="navbarVisibility">
        <b-button
          class="pageButton ml-2"
          type="button"
          :variant="currentTab === 0 ? 'light' : 'secondary'"
          :active="currentTab === 0"
          @click="currentTab = 0"
        >
          Nüfus Bilgileri
        </b-button>
        <b-button
          class="pageButton ml-2"
          type="button"
          :variant="currentTab === 1 ? 'light' : 'secondary'"
          :active="currentTab === 1"
          @click="currentTab = 1"
        >
          Branş Bilgileri
        </b-button>
        <b-button
          class="pageButton ml-2"
          type="button"
          :variant="currentTab === 2 ? 'light' : 'secondary'"
          :active="currentTab === 2"
          @click="currentTab = 2"
        >
          İletişim Bilgileri
        </b-button>
        <b-button
          class="pageButton ml-2"
          type="button"
          :variant="currentTab === 3 ? 'light' : 'secondary'"
          :active="currentTab === 3"
          @click="currentTab = 3"
        >
          Öğrenim Bilgileri
        </b-button>
        <b-button
          class="pageButton ml-2"
          type="button"
          :variant="currentTab === 4 ? 'light' : 'secondary'"
          :active="currentTab === 4"
          @click="currentTab = 4"
        >
          Sistem Bilgileri
        </b-button>
        <b-button
          class="pageButton ml-2"
          type="button"
          :variant="currentTab === 5 ? 'light' : 'secondary'"
          :active="currentTab === 5"
          @click="currentTab = 5"
        >
          Yarışmalar
        </b-button>
        <b-button
          class="pageButton ml-2"
          type="button"
          :variant="currentTab === 6 ? 'light' : 'secondary'"
          :active="currentTab === 6"
          @click="currentTab = 6"
          v-if="hasRole(['federation'])"
        >
          Vizeler
        </b-button>
      </div>
      <div class="buttonBarMobile" v-else>
        <b-button v-b-toggle.collapse-1 variant="dark">
          <b-icon icon="list"></b-icon
        ></b-button>
        <b-collapse id="collapse-1" class="mt-2">
          <b-col>
            <b-row>
              <b-button
                class="ml-2 mt-4 pageButtonMobile"
                type="button"
                :variant="currentTab === 0 ? 'light' : 'secondary'"
                :active="currentTab === 0"
                @click="currentTab = 0"
              >
                Nüfus Bilgileri
              </b-button>
            </b-row>
            <b-row>
              <b-button
                class="ml-2 mt-2 pageButtonMobile"
                type="button"
                :variant="currentTab === 1 ? 'light' : 'secondary'"
                :active="currentTab === 1"
                @click="currentTab = 1"
              >
                Branş Bilgileri
              </b-button>
            </b-row>
            <b-row>
              <b-button
                class="ml-2 mt-2 pageButtonMobile"
                type="button"
                :variant="currentTab === 2 ? 'light' : 'secondary'"
                :active="currentTab === 2"
                @click="currentTab = 2"
              >
                İletişim Bilgileri
              </b-button>
            </b-row>
            <b-row>
              <b-button
                class="ml-2 mt-2 pageButtonMobile"
                type="button"
                :variant="currentTab === 3 ? 'light' : 'secondary'"
                :active="currentTab === 3"
                @click="currentTab = 3"
              >
                Öğrenim Bilgileri
              </b-button>
            </b-row>
            <b-row>
              <b-button
                class="ml-2 mt-2 pageButtonMobile"
                type="button"
                :variant="currentTab === 4 ? 'light' : 'secondary'"
                :active="currentTab === 4"
                @click="currentTab = 4"
              >
                Sistem Bilgileri
              </b-button>
            </b-row>
            <b-row>
              <b-button
                class="ml-2 mt-2 pageButtonMobile"
                type="button"
                :variant="currentTab === 5 ? 'light' : 'secondary'"
                :active="currentTab === 5"
                @click="currentTab = 5"
              >
                Yarışmalar
              </b-button>
            </b-row>
            <b-row>
              <b-button
                class="ml-2 mt-2 pageButtonMobile"
                type="button"
                :variant="currentTab === 6 ? 'light' : 'secondary'"
                :active="currentTab === 6"
                @click="currentTab = 6"
                v-if="hasRole(['federation'])"
              >
                Vizeler
              </b-button>
            </b-row>
          </b-col>
        </b-collapse>
      </div>
      <div v-if="currentTab === 0" class="mt-4">
        <PersonalDisplay :form="form" />
      </div>
      <div v-else-if="currentTab === 1" class="mt-4">
        <BranchDisplay :form="form" />
      </div>
      <div v-else-if="currentTab === 2" class="mt-4">
        <ContactDisplay :form="form" />
      </div>
      <div v-else-if="currentTab === 3" class="mt-4">
        <EducationDisplay :form="form" />
      </div>
      <div v-else-if="currentTab === 4" class="mt-4">
        <SystemDisplay :form="form" />
      </div>
      <div v-else-if="currentTab === 5" class="mt-4">
        <CompetitionDisplay :form="form" />
      </div>
      <div v-else-if="currentTab === 6 && hasRole(['federation'])" class="mt-4">
        <VisaDisplay :form="form" />
      </div>
      <br />
      <b-row v-if="hasRole(['federation'])">
        <b-btn class="ml-4" @click="editClick">Güncelle</b-btn>
        <b-btn
          class="ml-2"
          v-if="!form.system.approval"
          variant="primary"
          @click="approvePersonel"
          >Personeli Onayla</b-btn
        >
        <b-btn class="ml-2" variant="warning" v-b-modal.visa-modal
          >Vize İşlemleri</b-btn
        >
        <b-btn class="ml-2" variant="info" v-b-modal.report-personel
          >Döküm Al</b-btn
        >
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import PersonalDisplay from "@/components/forms/personel/display/PersonalDisplay.vue";
import BranchDisplay from "@/components/forms/personel/display/BranchDisplay.vue";
import ContactDisplay from "@/components/forms/personel/display/ContactDisplay.vue";
import EducationDisplay from "@/components/forms/personel/display/EducationDisplay.vue";
import SystemDisplay from "@/components/forms/personel/display/SystemDisplay.vue";
import CompetitionDisplay from "@/components/forms/personel/display/CompetitionDisplay.vue";
import VisaDisplay from "@/components/forms/personel/display/VisaDisplay.vue";
import * as options from "@/store/constants/personel";
import selectionConstants from "@/store/constants/selections";
import cities from "@/store/constants/cities";
import reportHelper from "@/store/helpers/PMSReportHelper";
import { mapGetters } from "vuex";
export default {
  components: {
    PersonalDisplay,
    BranchDisplay,
    ContactDisplay,
    EducationDisplay,
    SystemDisplay,
    CompetitionDisplay,
    VisaDisplay,
  },
  data() {
    return {
      currentTab: 0,
      navbarVisibility: true,
      reportForm: {
        includeDetails: true,
        includeBranch: true,
        includeCompetitionGroups: true,
      },
    };
  },
  computed: {
    ...mapGetters([
      "getPersonelAddresItem",
      "getManagementItems",
      "getPersonelImage",
    ]),
    form: {
      get() {
        return this.getManagementItems.length
          ? this.parseForm(this.getPersonelAddresItem)
          : this.getPersonelAddresItem;
      },
    },
    getBoatClassSelections: {
      get() {
        return [{ title: "Seçin", category: "" }].concat(
          this.getManagementItems.filter((e) => {
            return e.category == selectionConstants.BOAT_CLASS; //BOAT FILTER
          })
        );
      },
    },

    getCategorySelections: {
      get() {
        return [{ title: "Seçin", category: "" }].concat(
          this.getManagementItems.filter((e) => {
            return e.category == selectionConstants.CATEGORY; //CATEGORY FILTER
          })
        );
      },
    },
    getBranchSelections: {
      get() {
        return [{ title: "Seçin", category: "" }].concat(
          this.getManagementItems.filter((e) => {
            return e.category == selectionConstants.BRANCH; //BRANCH FILTER
          })
        );
      },
    },
  },
  created() {
    this.$store.dispatch("initManagement").then(() => {
      if (this.$store.getters.checkToken.isFederation) {
        this.$store.dispatch("initpersonelAddresItem", this.$route.params.id);
        this.$store.dispatch("initPersonelImage", this.$route.params.id);
      } else {
        this.$store.dispatch(
          "initpersonelAddresItem",
          this.$store.getters.getUser.id
        );
      }
    });
    if (this.isMobile()) {
      this.navbarVisibility = false;
    }
  },
  methods: {
    onSubmit() {
      this.$store
        .dispatch("updatePersonel", this.form)
        .then(() => this.$router.push("/personel"));
    },
    setPage(val) {
      this.currentCard = val;
      this.currentPage = 0;
    },
    getColor(color) {
      if (this.currentCard == color) {
        return "color:white";
      } else {
        return "color:black";
      }
    },
    convert(value, option) {
      return value && option
        ? option.filter((e) => e.value == value)[0].text
        : "";
    },
    convertSelection(value, option) {
      return value && option
        ? option.filter((e) => e.id == value)[0].title
        : "";
    },
    async reportUser() {
      const result = await reportHelper.getPdfItemsFromServer(
        "single_personel",
        {
          id: this.$route.params.id,
          form: this.reportForm,
        }
      );

      reportHelper.downloadSinglePersonelReport(result);
    },
    hasRole(roles) {
      let vals = [];
      roles.forEach((role) => {
        if (role == "federation") {
          vals.push(this.$store.getters.checkToken.isFederation);
        } else if (role == "personel") {
          vals.push(this.$store.getters.checkToken.isPersonel);
        } else if (role == "club") {
          vals.push(this.$store.getters.checkToken.isClub);
        } else if (role == "provincialRepresentative") {
          vals.push(this.$store.getters.checkToken.isProvincialRepresentative);
        }
      });
      return vals.includes(true);
    },
    approvePersonel() {
      this.$store.dispatch("approvePersonel", [this.$route.params.id]);
    },
    visaPersonel(role) {
      const date = new Date().getFullYear();
      if (this.form.branch[role] && this.form.branch[role] > date - 3) {
        this.$store.dispatch("visaPersonel", {
          personelId: this.form.id,
          date,
          role,
        });
      } else {
        this.role = role;
        this.$bvModal.show("warning-modal");
      }
    },
    handleOk() {
      const date = new Date().getFullYear();
      this.$store.dispatch("visaPersonel", {
        personelId: this.form.id,
        date,
        role: this.role,
      });
    },
    editClick() {
      this.$router.push({
        name: "EditPersonel",
        params: this.form,
      });
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    parseForm(parsedForm) {
      const newForm = JSON.parse(JSON.stringify(parsedForm));
      newForm.image = this.getPersonelImage;
      newForm.gender = this.convert(newForm.gender, options.genderOptions);
      newForm.maritalStatus = this.convert(
        newForm.maritalStatus,
        options.maritalOptions
      );
      newForm.religion = this.convert(
        newForm.religion,
        options.religionOptions
      );
      newForm.bloodGrup = this.convert(newForm.bloodGrup, options.bloodOptions);
      newForm.reasonforGiven = this.convert(
        newForm.reasonforGiven,
        options.reasonOptions
      );
      newForm.branch.branch = this.convertSelection(
        newForm.branch.branch,
        this.getBranchSelections
      );
      newForm.branch.category = this.convertSelection(
        newForm.branch.category,
        this.getCategorySelections
      );
      newForm.branch.boatClass = this.convertSelection(
        newForm.branch.boatClass,
        this.getBoatClassSelections
      );
      newForm.branch.sportState = this.convert(
        newForm.branch.sportState,
        options.activityOptions
      );
      newForm.branch.individual = this.convert(
        newForm.branch.individual,
        options.activityOptions
      );
      newForm.branch.sportRefereeState = this.convert(
        newForm.branch.sportRefereeState,
        options.activityOptions
      );
      newForm.branch.trainerState = this.convert(
        newForm.branch.trainerState,
        options.activityOptions
      );
      newForm.branch.provincialRepresentative = this.convert(
        newForm.branch.provincialRepresentative,
        options.activityOptions
      );
      newForm.branch.assemblyMember = this.convert(
        newForm.branch.assemblyMember,
        options.activityOptions
      );
      newForm.branch.assembly = this.convert(
        newForm.branch.assembly,
        options.boardOptions
      );
      newForm.branch.personel = this.convert(
        newForm.branch.personel,
        options.activityOptions
      );
      newForm.branch.manager = this.convert(
        newForm.branch.manager,
        options.activityOptions
      );
      newForm.contact.cityWhereLocated = this.convert(
        newForm.contact.cityWhereLocated,
        cities
      );
      newForm.contact.message = newForm.contact.message ? "Evet" : "Hayır";
      newForm.education.educationStatus = this.convert(
        newForm.education.educationStatus,
        options.educationOptions
      );
      newForm.createdAt = parseDate(newForm.createdAt);
      newForm.dateForGiven = parseDate(newForm.dateForGiven);
      newForm.birthDate = parseDate(newForm.birthDate);
      newForm.branch.stillWaterCoachPromotionDate = parseDate(
        newForm.branch.stillWaterCoachPromotionDate
      );
      newForm.branch.stillWaterTrainerPromotionDate = parseDate(
        newForm.branch.stillWaterTrainerPromotionDate
      );
      newForm.branch.runningWaterCoachPromotionDate = parseDate(
        newForm.branch.runningWaterCoachPromotionDate
      );
      newForm.branch.runningWaterTrainerPromotionDate = parseDate(
        newForm.branch.runningWaterTrainerPromotionDate
      );
      newForm.branch.stillWaterHeadTrainerPromotionDate = parseDate(
        newForm.branch.stillWaterHeadTrainerPromotionDate
      );
      newForm.branch.stillWaterSeniorTrainerPromotionDate = parseDate(
        newForm.branch.stillWaterSeniorTrainerPromotionDate
      );
      newForm.branch.runningWaterHeadTrainerPromotionDate = parseDate(
        newForm.branch.runningWaterHeadTrainerPromotionDate
      );
      newForm.branch.stillWaterDistrictRefereePromotionDate = parseDate(
        newForm.branch.stillWaterDistrictRefereePromotionDate
      );
      newForm.branch.stillWaterNationalRefereePromotionDate = parseDate(
        newForm.branch.stillWaterNationalRefereePromotionDate
      );
      newForm.branch.runningWaterSeniorTrainerPromotionDate = parseDate(
        newForm.branch.runningWaterSeniorTrainerPromotionDate
      );
      newForm.branch.stillWaterCandidateRefereePromotionDate = parseDate(
        newForm.branch.stillWaterCandidateRefereePromotionDate
      );
      newForm.branch.stillWaterAssistantTrainerPromotionDate = parseDate(
        newForm.branch.stillWaterAssistantTrainerPromotionDate
      );
      newForm.branch.runningWaterDistrictRefereePromotionDate = parseDate(
        newForm.branch.runningWaterDistrictRefereePromotionDate
      );
      newForm.branch.runningWaterNationalRefereePromotionDate = parseDate(
        newForm.branch.runningWaterNationalRefereePromotionDate
      );
      newForm.branch.runningWaterCandidateRefereePromotionDate = parseDate(
        newForm.branch.runningWaterCandidateRefereePromotionDate
      );
      newForm.branch.runningWaterAssistantTrainerPromotionDate = parseDate(
        newForm.branch.runningWaterAssistantTrainerPromotionDate
      );
      newForm.branch.stillWaterInternationalRefereePromotionDate = parseDate(
        newForm.branch.stillWaterInternationalRefereePromotionDate
      );
      newForm.branch.runningWaterInternationalRefereePromotionDate = parseDate(
        newForm.branch.runningWaterInternationalRefereePromotionDate
      );
      return newForm;
      function parseDate(date) {
        if (!date) {
          return "";
        }
        const times = date.split("T");
        if (times.length > 0) {
          return (
            times[0].split("-").reverse().join(".") +
            (times[1] ? " " + times[1].split(".")[0].replace(":", ".") : "")
          );
        } else {
          return "";
        }
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
.selection-field {
  max-width: 16rem;
  min-width: 16rem;
}
.align-right {
  margin-right: 1rem;
}
.tab {
  color: white;
  background: gray;
}
.pageButton {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.pageButtonMobile {
  width: 12rem;
}
.buttonBar {
  text-align: end;
  margin-top: -3.65rem;
}
.buttonBarMobile {
  text-align: end;
  margin-top: -4rem;
}
.spacing {
  margin-top: 1.5rem;
}
.dark {
  color: black;
}
</style>