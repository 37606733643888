<template>
  <b-container class="mt-4">
    <b-row>
      <b-col>
        <b-img :src="form.image" height="192" width="160"></b-img>
      </b-col>
      <b-col>
        <b-row>
          <b-col>
            <div class="mt-4 text-danger font-weight-bold">TC Kimlik No :</div>
          </b-col>
          <b-col>
            <div class="mt-4 font-weight-bold">{{ form.ssn }}</div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="mt-4 text-danger font-weight-bold">Soyadı :</div>
          </b-col>
          <b-col>
            <div class="mt-4 font-weight-bold">{{ form.surname }}</div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="mt-4 text-danger font-weight-bold">Adı :</div>
          </b-col>
          <b-col>
            <div class="mt-4 font-weight-bold">{{ form.name }}</div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="mt-4 text-danger font-weight-bold">Cinsiyeti :</div>
          </b-col>
          <b-col>
            <div class="mt-4 font-weight-bold">{{ form.gender }}</div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="mt-4 text-danger font-weight-bold">Doğum Tarihi :</div>
          </b-col>
          <b-col>
            <div class="mt-4 font-weight-bold">{{ form.birthDate }}</div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "PersonalDisplay",
  props: ["form"],
};
</script>

<style scoped>
</style>