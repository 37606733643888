<template>
  <b-container class="mt-4">
    <b-row>
      <b-col class="mt-4 text-danger font-weight-bold">
        Kayıt Tarih Saat :
      </b-col>
      <b-col class="mt-4 font-weight-bold">
        {{ form.createdAt }}
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 text-danger font-weight-bold">Şifre :</b-col>
      <b-col class="mt-4 font-weight-bold">
        {{ form.system.password }}
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "EducationDisplay",
  props: ["form"],
};
</script>

<style scoped>
</style>