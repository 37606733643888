<template>
  <b-container class="mt-4">
    <b-row>
      <b-col class="mt-4 text-danger font-weight-bold"> İş Telefon No : </b-col>
      <b-col class="mt-4 font-weight-bold">
        {{ form.contact.workPhoneNumber }}
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 text-danger font-weight-bold">İş Adres :</b-col>
      <b-col class="mt-4 font-weight-bold">
        {{ form.contact.workAddress }}
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 text-danger font-weight-bold"> Ev Telefon No : </b-col>
      <b-col class="mt-4 font-weight-bold">
        {{ form.contact.homePhoneNumber }}
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 text-danger font-weight-bold">Ev Adres :</b-col>
      <b-col class="mt-4 font-weight-bold">
        {{ form.contact.homeAdress }}
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 text-danger font-weight-bold"> Yaşadığı İl : </b-col>
      <b-col class="mt-4 font-weight-bold">
        {{ form.contact.cityWhereLocated }}
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 text-danger font-weight-bold"> Yaşadığı İlçe : </b-col>
      <b-col class="mt-4 font-weight-bold">
        {{ form.contact.districtWhereLocated }}
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 text-danger font-weight-bold">
        Cep Telefon No :
      </b-col>
      <b-col class="mt-4 font-weight-bold">
        {{ form.contact.gsm }}
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 text-danger font-weight-bold"> E-Posta Adres : </b-col>
      <b-col class="mt-4 font-weight-bold">
        {{ form.contact.email }}
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 text-danger font-weight-bold">Web Adres :</b-col>
      <b-col class="mt-4 font-weight-bold">
        {{ form.contact.website }}
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 text-danger font-weight-bold">
        Banka Bilgileri :
      </b-col>
      <b-col class="mt-4 font-weight-bold">
        {{ form.contact.bankInfo }}
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 text-danger font-weight-bold">Mesaj :</b-col>
      <b-col class="mt-4 font-weight-bold">
        {{ form.contact.message }}
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "ContactDisplay",
  props: ["form"],
};
</script>

<style scoped>
</style>