<template>
  <b-container class="mt-4">
    <b-row>
      <b-col class="mt-4 text-danger font-weight-bold"> Öğrenim Durum : </b-col>
      <b-col class="mt-4 font-weight-bold">
        {{ form.education.educationStatus }}
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 text-danger font-weight-bold">
        Okul Adı / Bölüm / No :
      </b-col>
      <b-col class="mt-4 font-weight-bold">
        {{ form.education.schoolDepartmentNo }}
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 text-danger font-weight-bold">
        En Son Öğrenim Gördüğü Okul :
      </b-col>
      <b-col class="mt-4 font-weight-bold">
        {{ form.education.lastSchoolAttended }}
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 text-danger font-weight-bold"> Yabancı Dil : </b-col>
      <b-col class="mt-4 font-weight-bold">
        {{ form.education.foreignLanguage }}
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "EducationDisplay",
  props: ["form"],
};
</script>

<style scoped>
</style>