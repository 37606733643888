<template>
  <b-container class="mt-4">
    <b-modal id="singleDeletion" title="Dikkat!" @ok="deleteVisa">
      <p class="my-4">Vize kaydını silmek istediğinize emin misiniz?</p>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()">
          İptal
        </b-button>
        <b-button size="sm" variant="danger" @click="ok()"> Evet </b-button>
      </template>
    </b-modal>
    <div v-if="getPersonelVisas.length == 0">
      <b-alert show variant="warning">Kayıt bulunamadı</b-alert>
    </div>
    <b-table
      :fields="fields"
      :items="getPersonelVisas"
      striped
      responsive
      v-else
    >
      <template #cell(id)="id">
        <b-badge variant="secondary">
          {{ id.index + 1 }}
        </b-badge>
      </template>
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'name' ? '80%' : '10%' }"
        />
      </template>
      <template #cell(name)="row">
        {{ fieldToName(row.item.role) }}
      </template>
      <template #cell(tools)="row">
        <b-button
          size="sm"
          v-b-modal.singleDeletion
          @click="visaId = row.item.id"
          class="btn-danger"
        >
          <b-icon-trash></b-icon-trash>
        </b-button>
      </template>
    </b-table>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "VisaDisplay",
  props: ["form"],
  computed: {
    ...mapGetters(["getPersonelVisas"]),
  },
  data() {
    return {
      fields: [
        { label: "Sıra", key: "id" },
        { label: "Branş", key: "name" },
        { label: "Vize Yılı", key: "date" },
        { label: "İşlemler", key: "tools" },
      ],
      visaId: null,
    };
  },
  created() {
    this.$store.dispatch("initPersonelVisas", this.form.id);
  },
  methods: {
    fieldToName(field) {
      if (field.startsWith("sportR")) {
        return "Hakem";
      } else if (field.startsWith("trainer")) {
        return "Antrenör";
      } else if (field.startsWith("sportL")) {
        return "Sporcu";
      }
     else if (field.startsWith("streamWaterSportR")) {
        return "Akarsu Hakem";
      } else if (field.startsWith("stillWaterSportR")) {
        return "Durgunsu Hakem";
      }
      else if (field.startsWith("seaKayakingSportR")) {
        return "Deniz Kanosu Hakem";
      }
      else if (field.startsWith("dragonBoatSportR")) {
        return "Dragonboat Hakem";
      }
      else if (field.startsWith("raftingSportR")) {
        return "Rafting Hakem";
      }
      else if (field.startsWith("streamWaterSportL")) {
        return "Akarsu Sporcu";
      } else if (field.startsWith("stillWaterSportL")) {
        return "Durgunsu Sporcu";
      }
      else if (field.startsWith("seaKayakingSportL")) {
        return "Deniz Kanosu Sporcu";
      }
      else if (field.startsWith("dragonBoatSportL")) {
        return "Dragonboat Sporcu";
      }
      else if (field.startsWith("raftingSportL")) {
        return "Rafting Sporcu";
      }
      else if (field.startsWith("streamWaterTrainer")) {
        return "Akarsu Antrenör";
      } else if (field.startsWith("stillWaterTrainer")) {
        return "Durgunsu Antrenör";
      }
      else if (field.startsWith("seaKayakingTrainer")) {
        return "Deniz Kanosu Antrenör";
      }
      else if (field.startsWith("dragonBoatTrainer")) {
        return "Dragonboat Antrenör";
      }
      else if (field.startsWith("raftingTrainer")) {
        return "Rafting Antrenör";
      }
      
    },
    deleteVisa() {
      this.$store.dispatch("deleteVisa", {
        id: this.visaId,
        personelId: this.$route.params.id,
      });
    },
  },
};
</script>

<style scoped>
</style>